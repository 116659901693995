import FlightIcon from '@material-ui/icons/FlightTakeoff';
import FlightList from './FlightList';
import FlightShow from './FlightShow';

const flights = {
    icon: FlightIcon,
    list: FlightList,
    show: FlightShow,
    create: null,
    edit: null,
    options: {
        section: 'timeline',
        label: 'Flights'
    }
};

export default flights;
