import TagIcon from '@material-ui/icons/LocalOffer';
import TagList from './TagList';
import TagShow from './TagShow';
import TagCreate from "./TagCreate";
import TagEdit from "./TagEdit";

const tags = {
    icon: TagIcon,
    list: TagList,
    show: TagShow,
    create: TagCreate,
    edit: TagEdit,
    options: {
        section: 'Admin',
        label: 'Tags'
    }
};

export default tags;
