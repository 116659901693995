import React from "react";
import PropTypes from "prop-types";
import get from 'lodash/get';

const JsonField = ({source, record = {}}) => {
    let content = JSON.stringify(get(record, source), null, 2);

    // Remove hydra properties
    content     = content.replace(new RegExp(/{?"@\w+":\s+?("[^"]+"|{?\s+?[^}]+}),?\n?\s*/gi), '');
    // Pretty print in console
    console.info('%c%s\n%c%s', "font-weight:bold;font-size:2rem;font-family:sans-serif;", source, "display:block;font-weight:normal;font-family:monospace;font-size:1.5rem;padding:1rem;", content);
    // Colorize property names
    content = content.replace(new RegExp(/("\w+":)\s/gi), ' <span style="color: rgb(159,135,179)">$1</span> ');
    // Colorize null values
    content = content.replace(new RegExp(/\snull(,|\n)/gi), ' <span style="color: rgb(255,159,90)">null</span>$1');
    // Colorize digit values
    content = content.replace(new RegExp(/\s([-+]?[0-9]*\.?[0-9]+)(,|\n)/gi), ' <span style="color: rgb(71,209,255)">$1</span>$2');
    // Colorize boolean values
    content = content.replace(new RegExp(/\s(true)(,|\n)/gi), ' <span style="color: rgb(120,229,96)">$1</span>$2');
    content = content.replace(new RegExp(/\s(false)(,|\n)/gi), ' <span style="color: rgb(229,96,96)">$1</span>$2');
    // Colorize string values
    content = content.replace(new RegExp(/\s("[^"]+")(,|\n)/gi), ' <span style="color: rgb(208,201,142)">$1</span>$2');
    // Replace line-breaks
    content = content.replace(new RegExp(/\n/g), '<br/>');

    return (
            <div style={{
                width: 'auto',
                padding: '1rem 1rem 1.5rem',
                boxSizing: 'border-box'
            }}>
                <div style={{
                    width: 'auto',
                    padding: '1rem',
                    backgroundColor: 'rgb(34,38,40)',
                    color: 'rgb(166,175,181)',
                    fontFamily: 'monospace',
                    whiteSpace: 'pre',
                    boxSizing: 'border-box',
                    borderRadius: '2px'
                }} dangerouslySetInnerHTML={{__html: content}}/>
            </div>
    );
};

JsonField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default JsonField;
