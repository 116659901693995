import TimelineIcon from '@material-ui/icons/Timeline';
import TimelineList from './TimelineList';
import TimelineShow from './TimelineShow';

const timelines = {
    icon: TimelineIcon,
    list: TimelineList,
    show: TimelineShow,
    create: null,
    edit: null,
    options: {
        section: 'timeline',
        label: 'Timelines'
    }
};

export default timelines;
