import React from "react";
import {
    BooleanInput,
    SelectInput,
    TextInput,
    required
} from 'react-admin';
import {EditGuesser, InputGuesser} from "@api-platform/admin";

const CardEdit = props => (
        /*
                    <ReferenceInput label="Card deck"
                            source="cardDeck"
                            reference="card-decks"
                            validate={[required()]}
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput label="Channel"
                            source="channel"
                            reference="channels"
                            validate={[required()]}
            >
                <SelectInput optionText="name"/>
            </ReferenceInput>
         */
        <EditGuesser {...props}>
            <TextInput source="name" fullWidth={true}
                       helperText="Humanized card type root name, its transliterated machine version will be used for the first segment of the auto generated type field, eg. if you enter 'Commute to airport', the type will automatically begin by 'commute_to_airport'"/>
            <InputGuesser source="cardDeck" validate={[required()]}/>
            <InputGuesser source="channel" validate={[required()]}/>
            <SelectInput source="locale" choices={[
                {id: 'en', name: 'English'},
            ]}/>
            <SelectInput source="moment" choices={[
                {id: '', name: 'none'},
                {id: 'before', name: 'Before'},
                {id: 'dday', name: 'Dday'},
                {id: 'ongoing', name: 'Ongoing'},
                {id: 'late', name: 'Late'},
                {id: 'After', name: 'After'},
            ]}/>
            <SelectInput source="axis" choices={[
                {id: 'x', name: 'Will be sorted in X axis'},
                {id: 'y', name: 'Will be sorted in Y axis'},
                {id: 'z', name: 'Will be sorted in Z overlay'},
            ]}/>
            <TextInput source="cardBefore" fullWidth={true}
                       helperText="Exact type of default card before, leave empty to set as first of its axis stack"/>
            <TextInput source="cardAfter" fullWidth={true}
                       helperText="Exact type of default card after, leave empty to set as last of its axis stack"/>
            <TextInput source="type" fullWidth={true}
                       helperText="If submitted empty, the value of this field is auto generated following the pattern {name}__{channel}__{locale}__{moment}"/>
            <SelectInput source="format" choices={[
                {id: '', name: 'unset'},
                {id: 'json', name: 'JSON'},
                {id: 'xml', name: 'XML'},
                {id: 'html', name: 'HTML'},
            ]}/>
            <TextInput multiline source="template" fullWidth={true}
                       helperText="The json template itself, minified at submission"/>
            <TextInput multiline source="rules" fullWidth={true}
                       helperText="Business rules"/>
            <BooleanInput source="isPublished"/>
        </EditGuesser>
);

export default CardEdit;
