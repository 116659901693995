import RouteIcon from '@material-ui/icons/Directions';
import RouteList from './List';

const routes = {
    icon: RouteIcon,
    list: RouteList,
    options: {
        section: 'iata',
        label: 'Routes'
    }
};

export default routes;
