import jwtDecode from 'jwt-decode';
import jwtManagerLocalStorage from "./jwtManagerLocalStorage";

const login_uri                = process.env.REACT_APP_API_ENTRYPOINT + '/authentication_token';
const authProviderLocalStorage = {
    login: ({username, password}) => {
        const request = new Request(login_uri, {
            method: 'POST',
            body: JSON.stringify({email: username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(({token}) => {
                    jwtManagerLocalStorage.setToken(token);
                });
    },
    logout: () => {
        jwtManagerLocalStorage.eraseToken();
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (
                    !localStorage.getItem('token') ||
                    new Date().getTime() / 1000 >
                    jwtDecode(jwtManagerLocalStorage.getToken()).exp
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: err => {
        if ([401, 403].includes(err.status || err.response.status)) {
            jwtManagerLocalStorage.eraseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const roles = jwtManagerLocalStorage.getRoles();
        return roles ? Promise.resolve(roles) : Promise.reject();
    }
};

export default authProviderLocalStorage;
