import AirlineIcon from '@material-ui/icons/Business';
import AirlineList from './AirlineList';
import AirlineShow from "./AirlineShow";
import AirlineCreate from "./AirlineCreate";
import AirlineEdit from "./AirlineEdit";

const airline = {
    icon: AirlineIcon,
    list: AirlineList,
    show: AirlineShow,
    edit: AirlineEdit,
    create: AirlineCreate,
    options: {
        section: 'iata',
        label: 'Airlines'
    }
};

export default airline;
