import ChannelIcon from '@material-ui/icons/DevicesOther';
import ChannelList from "./ChannelList";
import ChannelShow from "./ChannelShow";
import ChannelCreate from "./ChannelCreate";
import ChannelEdit from "./ChannelEdit";

const channels = {
    icon: ChannelIcon,
    list: ChannelList,
    show: ChannelShow,
    create: ChannelCreate,
    edit: ChannelEdit,
    options: {
        section: 'timeline',
        label: 'Channels'
    }
}

export default channels;
