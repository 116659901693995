import AirportIcon from '@material-ui/icons/LocalAirport';
import AirportList from "./AirportList";
import AirportShow from "./AirportShow";
import AirportEdit from "./AirportEdit";
import AirportCreate from "./AirportCreate";

const airports = {
    icon: AirportIcon,
    list: AirportList,
    show: AirportShow,
    edit: AirportEdit,
    create: AirportCreate,
    options: {
        section: 'iata',
        label: 'Airports'
    }
};

export default airports;
