import React from 'react';
import {ShowGuesser} from "@api-platform/admin";

const CityShow = (props) => {
    return (
            <ShowGuesser {...props} />
    );
};

export default CityShow;
