import CityIcon from '@material-ui/icons/LocationCity';
import CityList from "./CityList";
import CityShow from "./CityShow";
import CityEdit from "./CityEdit";
import CityCreate from "./CityCreate";

const cities = {
    icon: CityIcon,
    list: CityList,
    show: CityShow,
    edit: CityEdit,
    create: CityCreate,
    options: {
        section: 'iata',
        label: 'Cities'
    }
};

export default cities;
