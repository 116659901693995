import React from 'react';
import {ShowGuesser} from "@api-platform/admin";

const ActiveCardShow = (props) => {
    return (
            <ShowGuesser {...props} resource="active-cards"/>
    );
};

export default ActiveCardShow;
