import MediaObjectIcon from '@material-ui/icons/PermMedia';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import JsonIcon from '@material-ui/icons/Storage';
import VideoIcon from '@material-ui/icons/Movie';
import {makeStyles} from "@material-ui/core/styles";
import {ImageField} from "react-admin";
import React from "react";

const useImageFieldStyles = makeStyles(theme => ({
    image: {
        width: '50%',
        maxHeight: 'unset',
    }
}));

const useIconStyles = makeStyles(theme => ({
    root: {
        margin: '0.5rem',
    },
    fontSizeLarge: {
        fontSize: '50px',
    }
}));

const ThumbnailFields = ({record, source}) => {
    const imageFieldClasses = useImageFieldStyles();
    const iconClasses       = useIconStyles();

    if (!record.thumbnail) {
        if (record.mimeType === 'application/pdf') {
            return (
                    <PdfIcon fontSize="large" classes={iconClasses}/>
            );
        }
        if (record.mimeType === 'application/json') {
            return (
                    <JsonIcon fontSize="large" classes={iconClasses}/>
            );
        }
        if (record.mimeType === 'video/mp4' || record.mimeType === 'video/ogg') {
            return (
                    <VideoIcon fontSize="large" classes={iconClasses}/>
            );
        }
        return (
                <MediaObjectIcon fontSize="large" classes={iconClasses}/>
        );
    }

    return (
            <ImageField source={source} classes={imageFieldClasses}/>
    );
};

export default ThumbnailFields;
