import UserIcon from '@material-ui/icons/SupervisorAccount';
import UserList from './UserList';
import UserShow from './UserShow';
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";

const users = {
    icon: UserIcon,
    list: UserList,
    show: UserShow,
    create: UserCreate,
    edit: UserEdit,
    options: {
        section: 'Admin',
        label: 'Users'
    }
};

export default users;
