import React from 'react';
import ThumbnailField from "../fields/ThumbnailField";
import {FieldGuesser, ShowGuesser} from "@api-platform/admin";

const MediaObjectShow = (props) => {
    return (
            <ShowGuesser {...props}>
                <ThumbnailField source="preview"/>
                <FieldGuesser source={"contentUrl"} addLabel={true} />
                <FieldGuesser source={"title"} addLabel={true} />
                <FieldGuesser source={"tags"} addLabel={true} />
                <FieldGuesser source={"mimeType"} addLabel={true} />
                <FieldGuesser source={"originalName"} addLabel={true} />
                <FieldGuesser source={"createdAt"} addLabel={true} />
                <FieldGuesser source={"updatedAt"} addLabel={true} />
            </ShowGuesser>
    );
};

export default MediaObjectShow;
