import AirportInfoIcon from '@material-ui/icons/Info';
import AirportInfoList from "./List";
import AirportInfoShow from "./Show";
import AirportInfoCreate from "./Create";
import AirportInfoEdit from "./Edit";

const airportInfos = {
    icon: AirportInfoIcon,
    list: AirportInfoList,
    show: AirportInfoShow,
    create: AirportInfoCreate,
    edit: AirportInfoEdit,
    options: {
        section: 'card data',
        label: 'Covid-19 airport infos'
    }
};

export default airportInfos;
