import ClientDeviceIcon from '@material-ui/icons/DevicesOther';
import ClientDeviceList from './ClientDeviceList';
import ClientDeviceShow from './ClientDeviceShow';
import ClientDeviceCreate from "./ClientDeviceCreate";
import ClientDeviceEdit from "./ClientDeviceEdit";

const clientDevices = {
    icon: ClientDeviceIcon,
    list: ClientDeviceList,
    show: ClientDeviceShow,
    create: ClientDeviceCreate,
    edit: ClientDeviceEdit,
    options: {
        section: 'Admin',
        label: 'Client devices'
    }
};

export default clientDevices;
