import React from 'react';
import {
    List,
    Datagrid,
    ChipField,
    TextField,
    TextInput,
    BooleanField,
    DateField,
    EditButton,
    ShowButton,
    Filter, SelectInput
} from 'react-admin';

const CardFilter = (props) => (
        <Filter {...props}>
            <TextInput label="card types" source="type" alwaysOn/>
            <SelectInput source="locale" choices={[
                {id: 'en', name: 'english'},
                {id: 'fr', name: 'french'},
                {id: 'es', name: 'spanish'},
                {id: 'de', name: 'german'},
                {id: 'zh', name: 'chinese'},
                {id: 'ru', name: 'russian'}
            ]} alwaysOn/>
        </Filter>
);

const CardList = props => (
        <List {...props} perPage={25} filters={<CardFilter/>} title="Tripset API Manager - cards">
            <Datagrid>
                <TextField source="type"/>
                <ChipField source="locale"/>
                <BooleanField source="isPublished"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default CardList;
