import React from 'react';
import {
    ArrayInput,
    BooleanInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleFormIterator,
    TextInput,
    required
} from 'react-admin';
import {CreateGuesser} from "@api-platform/admin";

const CardDeckCreate = props => (
        <CreateGuesser {...props} resource="card-decks">
            <TextInput source="name" validate={[required()]}/>
            <ArrayInput source="cardDataProviders">
                <SimpleFormIterator>
                    <TextInput/>
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceArrayInput label="Card Games" source="cardGames" reference="card-games">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <BooleanInput source="isActive"/>
        </CreateGuesser>
);

export default CardDeckCreate;
