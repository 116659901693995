import React from "react";
import {Create, SimpleForm, TextInput} from 'react-admin';

export const TagCreate = (props) => (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name"/>
            </SimpleForm>
        </Create>
);

export default TagCreate;
