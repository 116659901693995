import * as React from 'react';
import {createElement} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import {MenuItemLink, getResources} from 'react-admin';
//import {withRouter} from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';

const TripsetMenu = ({onMenuClick, logout}) => {
    const isXSmall  = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open      = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    let sections    = [];
    resources.map(resource => {
        if (resource.options && resource.options.section && !sections.includes(resource.options.section)) {
            sections.push(resource.options.section);
        }
        return true;
    });
    return (
            <div>
                <br/>
                <br/>
                <MenuItemLink
                        to="/"
                        primaryText="Dashboard"
                        leftIcon={<DashboardIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                />
                <br/>
                {sections.map((section, i) => (
                        <List
                                key={'section' + i}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        {section.toUpperCase()}
                                    </ListSubheader>
                                }
                        >
                            {resources.map((resource) => (
                                    (resource.options && resource.options.section && resource.options.section === section) && (
                                            <MenuItemLink
                                                    key={resource.name}
                                                    to={`/${resource.name}`}
                                                    primaryText={(resource.options && resource.options.label) || resource.name}
                                                    leftIcon={createElement(resource.icon)}
                                                    onClick={onMenuClick}
                                                    sidebarIsOpen={open}
                                            />)
                            ))}
                        </List>
                ))}
                {isXSmall && logout}
            </div>
    );
};

//export default withRouter(TripsetMenu);
export default TripsetMenu;
