import React from 'react';
import {ShowGuesser} from "@api-platform/admin";

const CardDeckShow = (props) => {
    return (
            <ShowGuesser {...props} resource="card-decks"/>
    );
};

export default CardDeckShow;
