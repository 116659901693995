const theme = {
    palette: {
        type: 'dark',
        primary: {
            text: '#ff906e',
            contrastText: '#ffffff',
            main: '#6ec6ff',
        },
        secondary: {
            light: '#6ec6ff',
            main: '#1a1a23',
            dark: '#0f0f13',
            contrastText: '#eee',
            text: '#ccc'
        },
    },
    typography: {
        title: {
            fontWeight: 400,
        },
    },
    sidebar: {
        width: 240,
        closedWidth: 55,
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};

export default theme;
