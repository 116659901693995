import React from 'react';
import {FieldGuesser, ShowGuesser} from "@api-platform/admin";

const UserShow = props => (
        <ShowGuesser {...props}>
            <FieldGuesser source={"email"} addLabel={true} />
            <FieldGuesser source={"roles"} addLabel={true} />
            <FieldGuesser source={"firstName"} addLabel={true} />
            <FieldGuesser source={"lastName"} addLabel={true} />
            <FieldGuesser source={"createdAt"} addLabel={true} />
            <FieldGuesser source={"updatedAt"} addLabel={true} />
        </ShowGuesser>
);

export default UserShow;
