import React from "react";
import {EditGuesser} from "@api-platform/admin";
import {
    ArrayInput,
    BooleanInput,
    ReferenceArrayInput,
    required,
    SelectArrayInput,
    SimpleFormIterator,
    TextInput
} from "react-admin";

const CardDeckEdit = props => (
        <EditGuesser {...props}>
            <TextInput source="name" validate={[required()]}/>
            <ArrayInput source="cardDataProviders">
                <SimpleFormIterator>
                    <TextInput/>
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceArrayInput label="Card Games" source="cardGames" reference="card-games">
                <SelectArrayInput optionText="name"/>
            </ReferenceArrayInput>
            <BooleanInput source="isActive"/>
        </EditGuesser>
);

export default CardDeckEdit;
