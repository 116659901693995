import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const AirlineInfoFilter = (props) => (
        <Filter {...props}>
            <TextInput label="iata code" source="iataCode" alwaysOn/>
            <TextInput label="Unique hash" source="uniqueHash" alwaysOn/>
        </Filter>
);

const AirlineInfoList = props => (
        <List {...props} perPage={25} filters={<AirlineInfoFilter/>}
              title="Tripset API Manager - Covid-19 airline infos">
            <Datagrid>
                <TextField source="iataCode"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default AirlineInfoList;
