import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import objectToArray from "../helpers/objectToArray";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const FlightsField = ({record, source}) => {
    const classes = useStyles();
    const flights = record.data[source] ? objectToArray(record.data[source]) : [];

    return (
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Flights">
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Origin</TableCell>
                            <TableCell align="right">Destination</TableCell>
                            <TableCell align="right">Aircraft</TableCell>
                            <TableCell align="right">Airline</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {flights.map((flight) => (
                                <TableRow key={flight.number + flight.origin}>
                                    <TableCell component="th" scope="row">
                                        {flight.number}
                                    </TableCell>
                                    <TableCell align="right">{flight.date}</TableCell>
                                    <TableCell align="right">{flight.origin}</TableCell>
                                    <TableCell align="right">{flight.destination}</TableCell>
                                    <TableCell align="right">{flight.aircraft}</TableCell>
                                    <TableCell align="right">{flight.airline}</TableCell>
                                </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    );
}

export default FlightsField;
