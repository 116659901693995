import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    EditButton,
    Filter,
    List,
    ShowButton,
    TextField,
    TextInput
} from 'react-admin';

const AirportFilter = (props) => (
        <Filter {...props}>
            <TextInput label="iata codes" source="iata" alwaysOn/>
            <TextInput label="name" source="name" alwaysOn/>
            <TextInput label="city" source="city" alwaysOn/>
            <TextInput label="city code" source="cityCode" alwaysOn/>
            <TextInput label="metropolitan code" source="metropolitanCode" alwaysOn/>
            <TextInput label="country code" source="countryIsoAlpha2" alwaysOn/>
            <BooleanInput label="activated" source="active" alwaysOn/>
            <BooleanInput label="metropolitan area" source="isMetropolitanArea" alwaysOn/>
        </Filter>
);

const AirportList = props => (
        <List {...props}
              perPage={25}
              filters={<AirportFilter/>}
              filterDefaultValues={{active: true}}
              title="Tripset API Manager - Airports">
            <Datagrid>
                <TextField source="iata"/>
                <TextField source="icao"/>
                <TextField source="name"/>
                <TextField source="city"/>
                <TextField source="cityCode"/>
                <TextField source="metropolitanCode"/>
                <TextField source="countryIsoAlpha2"/>
                <DateField source="updatedAt" showTime/>
                <BooleanField source="active"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default AirportList;
