import React from 'react';
import {FieldGuesser, ShowGuesser} from "@api-platform/admin";


const ClientDeviceShow = props => (
        <ShowGuesser {...props}>
            <FieldGuesser source={"cid"} addLabel={true}/>
            <FieldGuesser source={"userAgent"} addLabel={true}/>
            <FieldGuesser source={"authorized"} addLabel={true}/>
            <FieldGuesser source={"hit"} addLabel={true}/>
            <FieldGuesser source={"fcmToken"} addLabel={true}/>
            <FieldGuesser source={"addedAt"} addLabel={true}/>
            <FieldGuesser source={"lastConnectionOn"} addLabel={true}/>
        </ShowGuesser>
);

export default ClientDeviceShow;
