import CardGameIcon from '@material-ui/icons/RecentActors';
import CardGameList from "./CardGameList";
import CardGameCreate from "./CardGameCreate";
import CardGameShow from "./CardGameShow";
import CardGameEdit from "./CardGameEdit";

const cardGames = {
    icon: CardGameIcon,
    list: CardGameList,
    show: CardGameShow,
    create: CardGameCreate,
    edit: CardGameEdit,
    options: {
        section: 'timeline',
        label: 'Card games'
    }
};

export default cardGames;
