import React from 'react';
import {FileField, FileInput, TextInput} from "react-admin";
import ThumbnailField from "../fields/ThumbnailField";
import {EditGuesser} from "@api-platform/admin";

const MediaObjectEdit = (props) => {
    return (
            <EditGuesser {...props}>
                <ThumbnailField source="preview"/>
                <TextInput source="title" label="Title"/>
                <FileInput
                        accept="image/jpeg,image/png,image/svg,image/svg+xml,application/pdf,application/x-pdf,application/json,video/ogg,video/mp4"
                        source="file"
                        maxSize={2000000}
                        multiple={false}
                        label="New file">
                    <FileField source="src" title="title"/>
                </FileInput>
            </EditGuesser>
    );
};

export default MediaObjectEdit;
