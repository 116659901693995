import CardIcon from '@material-ui/icons/Subtitles';
import CardList from "./CardList";
import CardShow from "./CardShow";
import CardCreate from "./CardCreate";
import CardEdit from "./CardEdit";

const cards = {
    icon: CardIcon,
    list: CardList,
    show: CardShow,
    create: CardCreate,
    edit: CardEdit,
    options: {
        section: 'timeline',
        label: 'Cards'
    }
};

export default cards;
