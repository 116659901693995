import decodeJwt from 'jwt-decode';

const jwtManager = () => {
    let _token = null;
    let _roles = null;

    const getToken = () => _token;

    const setToken = (token) => {
        _token             = token;
        const decodedToken = decodeJwt(token);
        setRoles(decodedToken.roles);
        return true;
    };

    const eraseToken = () => {
        _token = null;
        eraseRoles();
        return true;
    };

    const getRoles = () => _roles;

    const setRoles = (roles) => {
        _roles = roles;
        return true;
    };

    const eraseRoles = () => {
        _roles = null;
        return true;
    };

    return {
        eraseToken,
        getToken,
        setToken,
        getRoles,
    };
};

export default jwtManager();
