import AirlineInfoIcon from '@material-ui/icons/Business';
import AirlineInfoList from "./List";
import AirlineInfoShow from "./Show";
import AirlineInfoCreate from "./Create";
import AirlineInfoEdit from "./Edit";

const airlineInfo = {
    icon: AirlineInfoIcon,
    list: AirlineInfoList,
    show: AirlineInfoShow,
    create: AirlineInfoCreate,
    edit: AirlineInfoEdit,
    options: {
        section: 'card data',
        label: 'Covid-19 airline infos'
    }
};

export default airlineInfo;
