import SherpaTravelRestrictionIcon from '@material-ui/icons/Storage';
import SherpaTravelRestrictionList from "./List";
import SherpaTravelRestrictionShow from "./Show";

const sherpaTravelRestrictions = {
    icon: SherpaTravelRestrictionIcon,
    list: SherpaTravelRestrictionList,
    show: SherpaTravelRestrictionShow,
    options: {
        section: 'card data',
        label: 'Sherpa travel restrictions'
    }
};

export default sherpaTravelRestrictions;
