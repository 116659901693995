import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    ShowButton,
    Filter, DateField, SelectInput
} from 'react-admin';
import ThumbnailField from "../fields/ThumbnailField";

const MediaObjectFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Original name" source="originalName" alwaysOn/>
            <TextInput label="Title" source="title" alwaysOn/>
            <SelectInput label="Type" source="mimeType" choices={[
                {name: 'jpeg', id: 'image/jpeg'},
                {name: 'png', id: 'image/png'},
                {name: 'svg', id: 'image/svg'},
                {name: 'pdf', id: 'application/pdf'},
                {name: 'json', id: 'application/json'},
                {name: 'mpeg-4', id: 'video/mp4'},
                {name: 'ogg', id: 'video/ogg'}
            ]} alwaysOn/>
        </Filter>
);

const MediaObjectList = props => {
    return (
            <List {...props}
                  perPage={25}
                  filters={<MediaObjectFilter/>}
                  filterDefaultValues={{active: true}}
                  title="Tripset API Manager - medias">
                <Datagrid>
                    <ThumbnailField source="thumbnail"/>
                    <TextField source="title"/>
                    <TextField source="originalName"/>
                    <TextField source="mimeType"/>
                    <DateField source="createdAt" showTime/>
                    <DateField source="updatedAt" showTime/>
                    <EditButton/>
                    <ShowButton/>
                </Datagrid>
            </List>
    );
}

export default MediaObjectList;
