import React from 'react';
import {FileField, FileInput, TextInput} from "react-admin";
import {CreateGuesser} from "@api-platform/admin";

const MediaObjectCreate = (props) => {
    return (
            <CreateGuesser {...props}>
                <TextInput source="title" label="Title"/>
                <FileInput
                        accept="image/jpeg,image/png,image/svg,image/svg+xml,application/pdf,application/x-pdf,application/json,video/ogg,video/mp4"
                        source="file"
                        maxSize={2000000}
                        multiple={false}>
                    <FileField source="src" title="title"/>
                </FileInput>
            </CreateGuesser>
    );
};

export default MediaObjectCreate;
