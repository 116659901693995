import React from 'react';
import {ShowGuesser} from "@api-platform/admin";

const AircraftShow = (props) => {
    return (
            <ShowGuesser {...props} resource="aircraft"/>
    );
};

export default AircraftShow;
