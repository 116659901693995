import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const CardDecksFilter = (props) => (
        <Filter {...props}>
            <TextInput label="names" source="name" alwaysOn/>
        </Filter>
);

const CardDecksList = props => (
        /*
        <ReferenceField label="Cards" source="cards" reference="cards" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
         */
        <List {...props} perPage={25} filters={<CardDecksFilter/>} title="Tripset API Manager - Cards decks">
            <Datagrid>
                <TextField source="name"/>
                <BooleanField source="isActive"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default CardDecksList;
