import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const TagFilter = (props) => (
        <Filter {...props}>
            <TextInput label="name" source="name" alwaysOn/>
        </Filter>
);

const TagList = props => (
        <List {...props} perPage={25} filters={<TagFilter/>} title="Tripset API Manager - Tags">
            <Datagrid>
                <TextField source="name"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default TagList;
