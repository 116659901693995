import React from "react";
import {Edit, SimpleForm, TextInput, BooleanInput} from 'react-admin';

export const ClientDeviceEdit = (props) => (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="cid"/>
                <TextInput source="userAgent"/>
                <BooleanInput source="authorized"/>
                <TextInput source="fcmToken"/>
            </SimpleForm>
        </Edit>
);

export default ClientDeviceEdit;
