import React from 'react';
import {EditGuesser} from "@api-platform/admin";

const AircraftEdit = (props) => {
    return (
            <EditGuesser {...props} resource="aircraft"/>
    );
};

export default AircraftEdit;
