import React from 'react';
import {Datagrid, DateField, Filter, List, ShowButton, TextField, TextInput, SelectInput} from 'react-admin';
import FlightsField from "../fields/FlightsField";

const TimelineFilter = (props) => (
        <Filter {...props}>
            <TextInput label="id" source="id" alwaysOn/>
            <TextInput label="client id" source="cid" alwaysOn/>
            <SelectInput source="state" choices={[
                {id: 'creating', name: 'creating'},
                {id: 'updating', name: 'updating'},
                {id: 'processing', name: 'processing'},
                {id: 'ready', name: 'ready'},
                {id: 'completed', name: 'completed'},
                {id: 'deletion', name: 'deletion'},
                {id: 'unprocessable', name: 'unprocessable'},
                {id: 'failed', name: 'failed'},
            ]} alwaysOn/>
            <SelectInput source="locale" choices={[
                {id: 'en', name: 'english'},
                {id: 'fr', name: 'french'},
                {id: 'es', name: 'spanish'},
                {id: 'de', name: 'german'},
                {id: 'zh', name: 'chinese'},
                {id: 'ru', name: 'russian'}
            ]} alwaysOn/>
        </Filter>
);

const TimelineList = props => (
        <List {...props} perPage={25} filters={<TimelineFilter/>} sort={{ field: 'updatedAt', order: 'DESC' }} title="Tripset API Manager - Timelines">
            <Datagrid>
                <TextField source="id"/>
                <TextField source="cid"/>
                <TextField source="state"/>
                <TextField source="locale"/>
                <FlightsField source="flights"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <DateField source="processedAt" showTime/>
                <ShowButton/>
            </Datagrid>
        </List>
);
export default TimelineList;
