import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    DateField,
    ShowButton,
    Filter, SelectInput
} from 'react-admin';

const SherpaTravelRestrictionFilter = (props) => (
        <Filter {...props}>
            <TextInput label="iso alpha 3 country code" source="countryIsoAlpha3" alwaysOn/>
            <TextInput label="category" source="category" alwaysOn/>
            <SelectInput source="language" choices={[
                {id: 'en', name: 'english'},
                {id: 'fr', name: 'french'},
                {id: 'es', name: 'spanish'},
                {id: 'de', name: 'german'},
                {id: 'zh', name: 'chinese'},
                {id: 'ru', name: 'russian'}
            ]} alwaysOn/>
            <TextInput label="Sherpa ID" source="sherpaId" alwaysOn/>
        </Filter>
);

const SherpaTravelRestrictionList = props => (
        <List {...props} perPage={25} filters={<SherpaTravelRestrictionFilter/>}
              title="Tripset API Manager - Sherpa travel restrictions">
            <Datagrid>
                <TextField source="countryIsoAlpha3"/>
                <TextField source="title"/>
                <TextField source="category"/>
                <TextField source="subCategory"/>
                <TextField source="language"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default SherpaTravelRestrictionList;
