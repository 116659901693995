import jwtManager from "./jwtManager";
import decodeJwt from 'jwt-decode';

const login_uri    = process.env.REACT_APP_API_ENTRYPOINT + '/authentication_token';
const authProvider = {
    login: ({username, password}) => {
        const request = new Request(login_uri, {
            method: 'POST',
            body: JSON.stringify({email: username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(({token}) => {
                    jwtManager.setToken(token);
                });
    },
    logout: () => {
        jwtManager.eraseToken();

        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (!jwtManager.getToken() || new Date().getTime() / 1000 > decodeJwt(jwtManager.getToken())?.exp) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    },
    checkError: error => {
        console.error('checkError', error);

        let status = error;
        if (error.response) {
            status = error.response.status;
        }
        if (status === 401 || status === 403) {
            jwtManager.eraseToken();
            return Promise.reject();
        }

        return Promise.resolve();
    },
    getPermissions: () => {
        const roles = jwtManager.getRoles();
        return roles ? Promise.resolve(roles) : Promise.reject();
    }
};

export default authProvider;
