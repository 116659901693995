import AircraftIcon from '@material-ui/icons/Flight';
import AircraftList from './AircraftList';
import AircraftShow from "./AircraftShow";
import AircraftEdit from "./AircraftEdit";
import AircraftCreate from "./AircraftCreate";

const aircraft = {
    icon: AircraftIcon,
    list: AircraftList,
    show: AircraftShow,
    create: AircraftCreate,
    edit: AircraftEdit,
    options: {
        section: 'iata',
        label: 'Aircraft'
    }
};

export default aircraft;
