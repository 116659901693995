import MediaObjectIcon from '@material-ui/icons/PermMedia';
import MediaObjectList from './MediaObjectList';
import MediaObjectShow from "./MediaObjectShow";
import MediaObjectCreate from "./MediaObjectCreate";
import MediaObjectEdit from "./MediaObjectEdit";

const mediaObjects = {
    icon: MediaObjectIcon,
    list: MediaObjectList,
    show: MediaObjectShow,
    edit: MediaObjectEdit,
    create: MediaObjectCreate,
    options: {
        section: 'Admin',
        label: 'Medias'
    }
};

export default mediaObjects;
