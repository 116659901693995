import React from 'react';
import {BooleanInput, NumberInput, SelectInput, TextInput} from "react-admin";
import {CreateGuesser} from "@api-platform/admin";

const AirportCreate = props => (
        <CreateGuesser {...props}>
            <TextInput source="iata" fullWidth={false}/>
            <TextInput source="icao" fullWidth={false}/>
            <TextInput source="name" fullWidth={true}/>
            <TextInput source="city" fullWidth={true}/>
            <TextInput source="cityCode" fullWidth={false}/>
            <TextInput source="metropolitanCode" fullWidth={false}/>
            <TextInput source="regionCode" fullWidth={false}/>
            <TextInput source="region" fullWidth={true}/>
            <TextInput source="country" fullWidth={true}/>
            <TextInput source="countryIsoAlpha2" fullWidth={false}/>
            <TextInput source="countryIsoAlpha3" fullWidth={false}/>
            <TextInput source="latitude" fullWidth={false}/>
            <TextInput source="longitude" fullWidth={false}/>
            <TextInput source="altitude" fullWidth={false}/>
            <TextInput source="dst" fullWidth={false}/>
            <NumberInput source="avgFlightsPerDay"/>
            <TextInput source="address" fullWidth={true}/>
            <TextInput source="phoneLocal" fullWidth={false}/>
            <TextInput source="phoneIntl" fullWidth={false}/>
            <TextInput source="website" fullWidth={true}/>
            <TextInput source="timezone" fullWidth={true}/>
            <SelectInput source="type" choices={[
                {id: '', name: 'Unknown'},
                {id: 'J', name: 'Mixed'},
                {id: 'C', name: 'Civilian'},
                {id: 'M', name: 'Military'},
            ]}/>
            <NumberInput source="weight"/>
            <BooleanInput source="active"/>
            <BooleanInput source="isMetropolitanArea"/>
        </CreateGuesser>
);

export default AirportCreate;
