import React from "react";
import {Create, SimpleForm, TextInput, BooleanInput} from 'react-admin';

export const ClientDeviceCreate = (props) => (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="cid"/>
                <TextInput source="userAgent"/>
                <BooleanInput source="authorized"/>
                <TextInput source="fcmToken"/>
            </SimpleForm>
        </Create>
);

export default ClientDeviceCreate;
