import React, {useState} from 'react';
import {Navigate, Route} from "react-router-dom";
import {
    fetchHydra as baseFetchHydra,
    HydraAdmin,
    hydraDataProvider as baseDataProvider,
    ResourceGuesser,
    useIntrospection
} from '@api-platform/admin';
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import theme from "./layout/theme";
import TripsetLayout from "./layout";
import jwtManagerMemory from "./jwtManager";
import authProviderMemory from './authProvider';
import jwtManagerLocalStorage from "./jwtManagerLocalStorage";
import authProviderLocalStorage from './authProviderLocalStorage';
import Dashboard from "./Dashboard";
import activeCards from './active-cards';
import aircraft from './aircraft';
import airlines from './airlines';
import airports from './airports';
import cities from './cities';
import routes from './routes';
import cardDecks from './card-decks';
import cardGames from './card-games';
import cards from './cards';
import timelines from './timelines';
import channels from './channels';
import users from './users';
import flights from './flights';
import flightSubscriptions from './flight-subscriptions';
import sherpaTravelRestrictions from './sherpa-travel-restrictions';
import covid19AirportInfos from './covid19/airportInfos';
import covid19AirlineInfos from './covid19/airlineInfos';
import clientDevices from './client-devices';
import mediaObjects from './media-objects';
import tags from './tags';

const useLocalStorage = true;
const entrypoint      = process.env.REACT_APP_API_ENTRYPOINT;

let authProvider = authProviderMemory;
let jwtManager   = jwtManagerMemory;
if (useLocalStorage) {
    authProvider = authProviderLocalStorage;
    jwtManager   = jwtManagerLocalStorage;
}

const getHeaders = () => jwtManager.getToken() ? {
    Authorization: `Bearer ${jwtManager.getToken()}`,
} : {};

const fetchHydra = (url, options = {}) => {
    //console.log('fetchHydra with headers: ', getHeaders());
    return baseFetchHydra(url, {
        ...options,
        headers: getHeaders(),
    });
}

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (jwtManager.getToken()) {
        introspect();
        return <></>;
    }
    return <Navigate to="/login"/>;
};

/*const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders()});
        return {api};
    } catch (result) {
        if (result.status !== 401) {
            throw result;
        }

        jwtManager.eraseToken();

        return {
            api: result.api,
            customRoutes: [
                <Route path="/" component={RedirectToLogin}/>
            ],
        };
    }
};*/

const apiDocumentationParser = (setRedirectToLogin) => async () => {
    try {
        setRedirectToLogin(false);

        return await parseHydraDocumentation(entrypoint, {headers: getHeaders});
    } catch (result) {
        const {api, response, status} = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        jwtManager.eraseToken();

        setRedirectToLogin(true);

        return {
            api,
            response,
            status,
        };
    }
};

//const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);

const dataProvider = (setRedirectToLogin) => baseDataProvider({
    entrypoint: entrypoint,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin)
});

const App = () => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    return (
            <HydraAdmin
                    theme={theme}
                    title="Tripset API Manager Dashboard"
                    dashboard={Dashboard}
                    dataProvider={dataProvider(setRedirectToLogin)}
                    authProvider={authProvider}
                    entrypoint={entrypoint}
                    layout={TripsetLayout}
            >
                {permissions => [
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="timelines" {...timelines} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="channels" {...channels} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="card-games" {...cardGames} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="card-decks" {...cardDecks} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="cards" {...cards} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="active-cards" {...activeCards} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="flights" {...flights} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="flight-subscriptions" {...flightSubscriptions} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="aircraft" {...aircraft} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="airlines" {...airlines} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="airports" {...airports} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="routes" {...routes} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="cities" {...cities} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="sherpa-travel-restrictions" {...sherpaTravelRestrictions} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="covid19-airport-infos" {...covid19AirportInfos} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="covid19-airline-infos" {...covid19AirlineInfos} />
                            : null,
                    permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="users" {...users} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="client-devices" {...clientDevices} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="media-objects" {...mediaObjects} />
                            : null,
                    permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SUPER_ADMIN') ?
                            <ResourceGuesser name="tags" {...tags} />
                            : null,
                ]}
            </HydraAdmin>
    );
}

export default App;
