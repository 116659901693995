import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    BooleanField,
    BooleanInput,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const RouteFilter = (props) => (
        <Filter {...props}>
            <BooleanInput label="activated" source="active" alwaysOn/>
        </Filter>
);

// @TODO: add sourceAirport, destinationAirport, airline
const RouteList = props => (
        <List {...props}
              perPage={25}
              filters={<RouteFilter/>}
              filterDefaultValues={{active: true}}
              title="Tripset API Manager - routes">
            <Datagrid>
                <ReferenceField label="Origin" source="sourceAirport" reference="airports">
                    <TextField source="iata"/>
                </ReferenceField>
                <ReferenceField label="Destination" source="destinationAirport" reference="airports">
                    <TextField source="iata"/>
                </ReferenceField>
                <ReferenceField label="Airline" source="airline" reference="airlines">
                    <TextField source="iata"/>
                </ReferenceField>
                <ReferenceField label="Aircraft" source="aircrafts" reference="aircraft">
                    <TextField source="iata"/>
                </ReferenceField>
                <DateField source="updatedAt" showTime/>
                <BooleanField source="active"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default RouteList;
