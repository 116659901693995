import React from 'react';
import {Datagrid, DateField, Filter, List, ShowButton, TextField, TextInput} from 'react-admin';

const FlightFilter = (props) => (
        <Filter {...props}>
            <TextInput label="number" source="number" alwaysOn/>
            <TextInput label="operating number" source="operatingNumber" alwaysOn/>
        </Filter>
);

const FlightList = props => (
        <List {...props} perPage={25} filters={<FlightFilter/>} title="Tripset API Manager - Flights">
            <Datagrid>
                <TextField source="number"/>
                <TextField source="operatingNumber"/>
                <DateField source="date"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <ShowButton/>
            </Datagrid>
        </List>
);
export default FlightList;
