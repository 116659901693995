import React from 'react';
import {FieldGuesser, ShowGuesser} from "@api-platform/admin";

const TagShow = props => (
        <ShowGuesser {...props}>
            <FieldGuesser source={"name"} addLabel={true}/>
            <FieldGuesser source={"slug"} addLabel={true}/>
        </ShowGuesser>
);

export default TagShow;
