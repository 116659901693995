import React from 'react';
import {ShowGuesser} from "@api-platform/admin";

const AirportShow = (props) => {
    return (
            <ShowGuesser {...props} />
    );
};

export default AirportShow;
