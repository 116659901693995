import CardDeckIcon from '@material-ui/icons/Reorder';
import CardDeckList from "./CardDeckList";
import CardDeckCreate from "./CardDeckCreate";
import CardDeckShow from "./CardDeckShow";
import CardDeckEdit from "./CardDeckEdit";

const cardDecks = {
    icon: CardDeckIcon,
    list: CardDeckList,
    show: CardDeckShow,
    create: CardDeckCreate,
    edit: CardDeckEdit,
    options: {
        section: 'timeline',
        label: 'Card decks'
    }
};

export default cardDecks;
