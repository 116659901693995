import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import FlightSubscriptionList from "./FlightSubscriptionList";
import FlightSubscriptionShow from "./FlightSubscriptionShow";

const flightSubscriptions = {
    icon: SubscriptionsIcon,
    list: FlightSubscriptionList,
    show: FlightSubscriptionShow,
    create: null,
    edit: null,
    options: {
        section: 'timeline',
        label: 'Flight Subscriptions'
    }
};

export default flightSubscriptions;
