import ActiveCardIcon from '@material-ui/icons/DynamicFeed';
import ActiveCardList from './ActiveCardList';
import ActiveCardShow from './ActiveCardShow';

const activeCards = {
    icon: ActiveCardIcon,
    list: ActiveCardList,
    show: ActiveCardShow,
    create: null,
    edit: null,
    options: {
        section: 'timeline',
        label: 'Active cards'
    }
}

export default activeCards;
