import React from 'react';
import {DateField, NumberField, TextField} from "react-admin";
import {ListGuesser} from "@api-platform/admin";

const ActiveCardList = (props) => {
    return (
            <ListGuesser {...props}>
                <TextField source={"card"}/>
                <TextField source={"cardDeal"}/>
                <TextField source={"axis"}/>
                <TextField source={"cardBefore"}/>
                <TextField source={"cardAfter"}/>
                <NumberField source={"segmentIndex"}/>
                <DateField source={"createdAt"} showTime/>
                <DateField source={"updatedAt"} showTime/>
            </ListGuesser>
    );
};

export default ActiveCardList;
