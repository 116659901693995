import React from 'react';
import {EditGuesser} from "@api-platform/admin";

const AirlineEdit = (props) => {
    return (
            <EditGuesser {...props}/>
    );
};

export default AirlineEdit;
