import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Filter,
    List,
    NumberField,
    ShowButton,
    TextField,
    TextInput
} from 'react-admin';

const FlightSubscriptionFilter = (props) => (
        <Filter {...props}>
            <TextInput label="id" source="id" alwaysOn/>
            <BooleanInput label="activated" source="active" alwaysOn/>
        </Filter>
);

const FlightSubscriptionList = props => (
        <List {...props} perPage={25} filters={<FlightSubscriptionFilter/>} title="Tripset API Manager - Flights">
            <Datagrid>
                <TextField source="id"/>
                <TextField source="flight.number"/>
                <DateField source="flight.initialDepartureDate" showTime/>
                <DateField source="validUntil" showTime/>
                <NumberField source="hit"/>
                <BooleanField source="active"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <ShowButton/>
            </Datagrid>
        </List>
);
export default FlightSubscriptionList;
