import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    NumberField,
    DateField,
    EditButton,
    ShowButton,
    Filter, BooleanInput, BooleanField
} from 'react-admin';

const ClientDeviceFilter = (props) => (
        <Filter {...props}>
            <TextInput label="cid" source="cid" alwaysOn/>
            <TextInput label="user agent" source="userAgent" alwaysOn/>
            <BooleanInput label="authorized" source="authorized" alwaysOn/>
        </Filter>
);

const ClientDeviceList = props => (
        <List {...props} perPage={25} filters={<ClientDeviceFilter/>} title="Tripset API Manager - Client devices">
            <Datagrid>
                <TextField source="cid"/>
                <TextField source="userAgent"/>
                <BooleanField source="authorized"/>
                <NumberField source="hit"/>
                <DateField source="addedAt" showTime/>
                <DateField source="lastConnectionOn" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default ClientDeviceList;
