import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const CardGameFilter = (props) => (
        <Filter {...props}>
            <TextInput label="names" source="name" alwaysOn/>
        </Filter>
);

const CardGameList = props => (
        /*
        <ReferenceField label="Card decks" source="cardDecks" reference="card-decks">
                    <TextField source="name"/>
                </ReferenceField>
         */
        <List {...props} perPage={25} filters={<CardGameFilter/>} title="Tripset API Manager - Card games">
            <Datagrid>
                <TextField source="name"/>
                <BooleanField source="isActive"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default CardGameList;
