import React from 'react';
import {FieldGuesser, ShowGuesser} from "@api-platform/admin";

const CardGameShow = (props) => (
        <ShowGuesser {...props} resource="card-games">
            <FieldGuesser source={"name"} addLabel={true}/>
            <FieldGuesser source={"description"} addLabel={true}/>
            <FieldGuesser source={"cardDecks"} addLabel={true}/>
            <FieldGuesser source={"rules"} addLabel={true}/>
            <FieldGuesser source={"isActive"} addLabel={true}/>
            <FieldGuesser source={"slug"} addLabel={true}/>
            <FieldGuesser source={"createdAt"} addLabel={true}/>
            <FieldGuesser source={"updatedAt"} addLabel={true}/>
        </ShowGuesser>
);

export default CardGameShow;
