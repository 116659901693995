import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const UserFilter = (props) => (
        <Filter {...props}>
            <TextInput label="emails" source="email" alwaysOn/>
            <TextInput label="first names" source="firstName" alwaysOn/>
            <TextInput label="last names" source="lastName" alwaysOn/>
        </Filter>
);

const UserList = props => (
        <List {...props} perPage={25} filters={<UserFilter/>} title="Tripset API Manager - Users">
            <Datagrid>
                <TextField source="email"/>
                <TextField source="firstName"/>
                <TextField source="lastName"/>
                <TextField source="email"/>
                <TextField source="roles"/>
                <DateField source="createdAt"/>
                <DateField source="updatedAt"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default UserList;
