import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    EditButton,
    ShowButton,
} from 'react-admin';

const ChannelList = props => (
        <List {...props} perPage={25} title="Tripset API Manager - channels">
            <Datagrid>
                <TextField source="name"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default ChannelList;
