import React from "react";
import {Edit, SelectArrayInput, SimpleForm, TextInput, PasswordInput} from 'react-admin';

export const UserEdit = (props) => (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="email"/>
                <TextInput source="firstName"/>
                <TextInput source="lastName"/>
                <SelectArrayInput label="Roles" source="roles" choices={[
                    {id: 'ROLE_USER', name: 'basic user'},
                    {id: 'ROLE_API_DOC', name: 'api doc user'},
                    {id: 'ROLE_API', name: 'api user'},
                    {id: 'ROLE_CONTRIBUTOR', name: 'contributor user'},
                    {id: 'ROLE_ADMIN', name: 'admin user'},
                    {id: 'ROLE_SUPER_ADMIN', name: 'super admin user'},
                ]}/>
                <PasswordInput source="password"/>
            </SimpleForm>
        </Edit>
);

export default UserEdit;
