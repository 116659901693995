import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    BooleanInput,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const AircraftFilter = (props) => (
        <Filter {...props}>
            <TextInput label="iata codes" source="iata" alwaysOn/>
            <TextInput label="types" source="type" alwaysOn/>
            <BooleanInput label="activated" source="active" alwaysOn/>
        </Filter>
);

const AircraftList = props => (
        <List {...props}
              perPage={25}
              filters={<AircraftFilter/>}
              filterDefaultValues={{active: true}}
              title="Tripset API Manager - aircraft">
            <Datagrid>
                <TextField source="iata"/>
                <TextField source="icao"/>
                <TextField source="type"/>
                <DateField source="updatedAt" showTime/>
                <BooleanField source="active"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default AircraftList;
