import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const useStyles  = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    media: {
        height: 345,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

export default function MediaCard() {
    const classes = useStyles();

    return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h4" component="h1">Tripset API Manager</Typography>
                            <Typography variant="h6" component="h2">You are connected with {entrypoint}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={classes.root}>
                            <CardActionArea href="#/client-devices">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        View client devices
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        This reporting is anonymous.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={classes.root}>
                            <CardActionArea href="#/timelines">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        View timelines
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Timelines are readonly.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={classes.root}>
                            <CardActionArea href="#/cards">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Manage cards
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Cards are mainly defined by their templates.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </div>
    );
}
