import React from 'react';
import {EditGuesser} from "@api-platform/admin";

const CityEdit = (props) => {
    return (
            <EditGuesser {...props}/>
    );
};

export default CityEdit;
