import React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

//import logo from './iflya380-appicon.png';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logo: {
        maxHeight: '50px',
    },
    spacer: {
        flex: 1,
    }
};


const TripsetAppBar = withStyles(styles)(({classes, ...props}) => (
        <AppBar {...props}>
            <Typography
                    variant="subtitle1"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
            />
            <span className={classes.spacer}/>
        </AppBar>
));

export default TripsetAppBar;
