import React from 'react';
import {CreateGuesser} from "@api-platform/admin";

const AirlineCreate = (props) => {
    return (
            <CreateGuesser {...props}/>
    );
};

export default AirlineCreate;
