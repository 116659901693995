import {FieldGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import 'react-json-beautify/react-json-beautify.css';
import JsonField from "../fields/JsonField";
import {ReferenceField, TextField} from "react-admin";

// @TODO: implement https://github.com/react-syntax-highlighter/react-syntax-highlighter
const CardShow = props => (
        <ShowGuesser {...props}>
            <FieldGuesser source="name" addLabel={true}/>
            <ReferenceField label="Card decks" source="cardDeck" reference="card-decks">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField label="Channel" source="channel" reference="channels">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="type" addLabel={true}/>
            <FieldGuesser source="locale" addLabel={true}/>
            <FieldGuesser source="moment" addLabel={true}/>
            <FieldGuesser source="axis" addLabel={true}/>
            <FieldGuesser source="cardBefore" addLabel={true}/>
            <FieldGuesser source="cardAfter" addLabel={true}/>
            <FieldGuesser source="format" addLabel={true}/>
            <FieldGuesser source="rules" addLabel={true}/>
            <FieldGuesser source="isPublished" addLabel={true}/>
            <FieldGuesser source="createdAt" addLabel={true} showTime/>
            <FieldGuesser source="updatedAt" addLabel={true} showTime/>
            <JsonField source="template"/>
        </ShowGuesser>
);

export default CardShow;
