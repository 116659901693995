import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    BooleanInput,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const AirlineFilter = (props) => (
        <Filter {...props}>
            <TextInput label="iata codes" source="iata" alwaysOn/>
            <TextInput label="name" source="name" alwaysOn/>
            <BooleanInput label="activated" source="active" alwaysOn/>
        </Filter>
);

const AirlineList = props => (
        <List {...props}
              perPage={25}
              filters={<AirlineFilter/>}
              filterDefaultValues={{active: true}}
              title="Tripset API Manager - airlines">
            <Datagrid>
                <TextField source="iata"/>
                <TextField source="icao"/>
                <TextField source="name"/>
                <DateField source="updatedAt" showTime/>
                <BooleanField source="active"/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default AirlineList;
