import React from 'react';
import JsonField from "../fields/JsonField";
import {DateField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin';

const TimelineShow = (props) => {
    return (
            <Show {...props}>
                <TabbedShowLayout>
                    <Tab label="summary">
                        <TextField source="id"/>
                        <TextField source="cid"/>
                        <TextField source="state"/>
                        <DateField source="createdAt" showTime/>
                        <DateField source="updatedAt" showTime/>
                        <br/>
                    </Tab>
                    <Tab label="data" path="data">
                        <JsonField source={"data"} addLabel={false}/>
                    </Tab>
                    <Tab label="timeline" path="timeline">
                        <JsonField source={"timeline"} addLabel={false}/>
                    </Tab>
                </TabbedShowLayout>
            </Show>
    );
};

export default TimelineShow;
