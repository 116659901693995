import decodeJwt from 'jwt-decode';

const jwtManager = () => {

    const getToken = () => localStorage.getItem("token");

    const setToken = (token) => {
        localStorage.setItem("token", token);
        return true;
    };

    const eraseToken = () => {
        localStorage.removeItem("token");
        return true;
    };

    const getRoles = () => {
        const decodedToken = decodeJwt(getToken());
        return decodedToken.roles;
    };

    return {
        eraseToken,
        getToken,
        setToken,
        getRoles,
    };
};

export default jwtManager();
