import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    DateField,
    EditButton,
    ShowButton,
    Filter
} from 'react-admin';

const AirportInfoFilter = (props) => (
        <Filter {...props}>
            <TextInput label="iata code" source="iataCode" alwaysOn/>
            <TextInput label="airport name" source="airportName" alwaysOn/>
            <TextInput label="unique hash" source="uniqueHash" alwaysOn/>
        </Filter>
);

const AirportInfoList = props => (
        <List {...props} perPage={25} filters={<AirportInfoFilter/>}
              title="Tripset API Manager - Covid-19 airport infos">
            <Datagrid>
                <TextField source="iataCode"/>
                <TextField source="airportName"/>
                <DateField source="createdAt" showTime/>
                <DateField source="updatedAt" showTime/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
);

export default AirportInfoList;
